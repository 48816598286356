<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
        <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" />
        <!-- End Header Area -->

        <!-- Start Slider Area -->
        <div class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
            data-black-overlay="1" style="height: 30vh !important;">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="heading-title1 text-h2 text-left font-weight-bold"
                            style="font-size: 80px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                            Application Engineering
                        </h2>
                    </v-col>
                    <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light"
                            style="line-height: 40px !important;">
                            <!-- Streamline Your Geospatial Data Pipeline<br>Process, Analyze, and Visualize Geospatial Data with Ease -->
                            Accelerate transformation for exponential growth
                        </h5>
                    </v-col>
                </v-row>
            </v-container>

            <div class="video-background">
                <span>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.20) 50%, rgb(41, 41, 44));"></div>
                    <video muted="" autoplay="" playsinline="" loop="" poster="">
                        <source src="../../../assets/images/bg/marine-slider-vid.mp4" />
                    </video>
                </span>
            </div>
        </div>
        <!-- End Slider Area -->
        <v-toolbar color="#dd8732" class="toolbar-container" ref="toolbar" >
            <v-row>
                <v-col></v-col>
                <v-col cols="12" sm="11" md="10" lg="9" xl="8">
                    <v-tabs active-class="active-tab" color="white" v-model="tabContent">
                        <v-tab>
                            <strong>Marine Traffic Surveillance System</strong>
                        </v-tab>
                        <v-tab>
                            <strong>Air Traffic Surveillance System</strong>
                        </v-tab>
                        <v-tab>
                            <strong>Ground Asset Management System</strong>
                        </v-tab>
                        <v-tab>
                            <strong>In-Platform</strong>
                        </v-tab>
                    </v-tabs>
                </v-col>
                <v-col></v-col>
            </v-row>
        </v-toolbar>
        <!-- Start Content -->
        <v-tabs-items v-model="tabContent">
            <!-- <v-tab-item>
                <ApplicationServices />
            </v-tab-item>
            <v-tab-item>
                <Geopipe />
            </v-tab-item> -->
            <v-tab-item>
                <MarineSurveillanceSystem />
            </v-tab-item>
            <v-tab-item>
                <AirTrafficSurveillance />
            </v-tab-item>
            <v-tab-item>
                <GroundAssetManagement />
            </v-tab-item>
            <v-tab-item>
                <InPlatform />
            </v-tab-item>
        </v-tabs-items>
        <!-- End Content -->
        <Footer />
    </div>
</template>
  
<script>
import HeaderOnePageTwo from "../../../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../../../components/header/HeaderOnePageTwoSiberMobile.vue";
import Contact from "../../../components/contact/Contact";
import FeatureArea from "../../../components/brand/BrandFeatureMarine.vue";
import ServiceMarine from "../../../components/service/ServiceMarine.vue";
import Footer from "../../../components/footer/Footer";
import VueSlickCarousel from "vue-slick-carousel";
import feather from "feather-icons";

import ApplicationServices from '../../ApplicationServices.vue'
import Geopipe from '../../Geopipe.vue';
import MarineSurveillanceSystem from '../../MarineSurveillanceSystem.vue';
import AirTrafficSurveillance from '../../AirTrafficSurveillance.vue';
import GroundAssetManagement from '../../GroundAssetManagement.vue';
import InPlatform from '../../InPlatform.vue';

export default {
    components: {
        HeaderOnePageTwo,
        HeaderOnePageTwoMobile,
        Contact,
        FeatureArea,
        ServiceMarine,
        Footer,
        // PortfolioAwards,
        VueSlickCarousel,
        ApplicationServices,
        Geopipe,
        MarineSurveillanceSystem,
        AirTrafficSurveillance,
        GroundAssetManagement,
        InPlatform,
    },
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg()
        }
    },
    data() {
        return {
            lastScrollPosition: 0,
            tabContent: '0',
            serviceContent: [
                {
                    icon: "briefcase",
                    title: "Streamline Your Workflow",
                    desc: `Our platform is designed to make working with geospatial data easier and more efficient, saving you time and effort.`,
                },
                {
                    icon: "check",
                    title: "Increase Accuracy and Consistency",
                    desc: `Our built-in data cleaning and preprocessing tools help ensure the accuracy and consistency of your data.`,
                },
                {
                    icon: "bar-chart",
                    title: "Extract Valuable Insights",
                    desc: `Our range of spatial analysis and modeling tools can help you extract valuable insights and patterns from your geospatial data.`,
                },
                {
                    icon: "pie-chart",
                    title: "Create Stunning Visualizations",
                    desc: `Our powerful visualization tools and templates make it easy to create stunning visualizations of your geospatial data, helping you communicate your insights effectively.`,
                },
            ],
            serviceWhite: [
                {
                    img: require("../../../assets/images/about/finding-us-01.jpg"),
                    logo: require("../../../assets/images/about/finding-us-01.jpg"),
                    title: 'Ingest Data',
                    description: 'Our platform simplifies the process of ingesting geospatial data. With our adaptable engine, you can easily import your data in various formats, such as shapefiles or geodatabases, ensuring compatibility with your existing datasets.',
                    linkDetail: '/'
                },
                {
                    img: require("../../../assets/images/about/finding-us-01.jpg"),
                    logo: require("../../../assets/images/about/finding-us-01.jpg"),
                    title: 'Clean and Preprocess',
                    description: 'Save time and effort with our built-in tools and scripts that streamline data cleaning and preprocessing. Detect and eliminate errors, validate data integrity, and standardize formats, ensuring the accuracy and reliability of your geospatial information.',
                    linkDetail: '/'
                },
                {
                    img: require("../../../assets/images/about/finding-us-01.jpg"),
                    logo: require("../../../assets/images/about/finding-us-01.jpg"),
                    title: 'Analyze and Model',
                    description: 'Unlock the full potential of your geospatial data using our comprehensive suite of spatial analysis techniques. Explore relationships, perform spatial queries, conduct statistical analyses, and generate models to reveal patterns, trends, and valuable insights.',
                    linkDetail: '/'
                },
                {
                    img: require("../../../assets/images/about/finding-us-01.jpg"),
                    logo: require("../../../assets/images/about/finding-us-01.jpg"),
                    title: 'Visualize and Share',
                    description: 'Transform your geospatial data into captivating visualizations. Utilize our powerful visualization tools and templates to create stunning maps, charts, and interactive displays. Easily share your visualizations and insights with stakeholders, enabling effective communication and collaboration.',
                    linkDetail: '/'
                },
            ],
            showContent: true,
            activeService: 0,
            settings: {
                dots: true,
                arrows: true,
                infinite: true,
                speed: 500,
                slidesToShow: 3, // Adjusted value based on the number of items
                slidesToScroll: 1,
                initialSlide: 0,

                // responsive: [
                //   {
                //     breakpoint: 1600,
                //     settings: {
                //       slidesToShow: 4,
                //       slidesToScroll: 1,
                //       infinite: true,
                //       dots: true,
                //     },
                //   },
                //   {
                //     breakpoint: 1263,
                //     settings: {
                //       slidesToShow: 3,
                //       slidesToScroll: 1,
                //       infinite: true,
                //       dots: true,
                //     },
                //   },
                //   {
                //     breakpoint: 770,
                //     settings: {
                //       slidesToShow: 2,
                //       slidesToScroll: 1,
                //       initialSlide: 1,
                //     },
                //   },
                //   {
                //     breakpoint: 599,
                //     settings: {
                //       slidesToShow: 2,
                //       slidesToScroll: 1,
                //       initialSlide: 1,
                //     },
                //   },
                //   {
                //     breakpoint: 480,
                //     settings: {
                //       slidesToShow: 1,
                //       slidesToScroll: 1,
                //       arrows: false,
                //     },
                //   },
                // ],
            },
            portfolioContent: [
                {
                    meta: "Data Ingestion",
                    title: "Easily upload and import geospatial data from a variety of sources, including cloud storage, databases, and APIs.",
                },
                {
                    meta: "Data Cleaning and Preprocessing",
                    title: "Clean, validate, and preprocess your data to ensure accuracy and consistency.",
                },
                {
                    meta: "Spatial Analysis and Modeling",
                    title: "Analyze and model your geospatial data using a range of spatial analysis techniques and tools.",
                },
                {
                    meta: "Data Visualization",
                    title: "Create stunning visualizations of your geospatial data using our powerful visualization tools and templates.",
                },
            ],
            features: [
                {
                    // iconFeatures: require("../../../assets/images/icons/logo-icon.png"),
                    iconFeatures: require("../../../assets/images/icons/monitor.svg"),
                    titleFeatures: "Vessel Traffic Monitoring",
                },
                {
                    // iconFeatures: require("../../../assets/images/icons/logo-icon.png"),
                    iconFeatures: require("../../../assets/images/icons/filter.svg"),
                    titleFeatures: "Vessel Search & Filtering",
                },
                {
                    // iconFeatures: require("../../../assets/images/icons/logo-icon.png"),
                    iconFeatures: require("../../../assets/images/icons/info.svg"),
                    titleFeatures: "Vessel Voyage & Port Information Panel",
                },
                {
                    // iconFeatures: require("../../../assets/images/icons/logo-icon.png"),
                    iconFeatures: require("../../../assets/images/icons/play.svg"),
                    titleFeatures: "Playback Voyage",
                },
                {
                    // iconFeatures: require("../../../assets/images/icons/logo-icon.png"),
                    iconFeatures: require("../../../assets/images/icons/database.svg"),
                    titleFeatures: "Real-time Data Source Processing",
                },
                {
                    // iconFeatures: require("../../../assets/images/icons/logo-icon.png"),
                    iconFeatures: require("../../../assets/images/icons/vessel.svg"),
                    titleFeatures: "Vessel Even",
                },
                {
                    // iconFeatures: require("../../../assets/images/icons/logo-icon.png"),
                    iconFeatures: require("../../../assets/images/icons/cloud.svg"),
                    titleFeatures: "Weather Forecast",
                },
                {
                    // iconFeatures: require("../../../assets/images/icons/logo-icon.png"),
                    iconFeatures: require("../../../assets/images/icons/bell.svg"),
                    titleFeatures: "Custom Alert & Notification",
                },
                {
                    // iconFeatures: require("../../../assets/images/icons/logo-icon.png"),
                    iconFeatures: require("../../../assets/images/icons/pie-chart.svg"),
                    titleFeatures: "Analytics & Reporting",
                },
            ]
        };
    },
    watch: {
        activeService(e, v) {
            // console.log(e)
            this.showContent = false
            this.$nextTick(() => {
                this.showContent = true
            })
        },
    }
};
</script>
<style scoped>
.heading-title1 {
    color: #dd8732 !important;
    line-height: 60px;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-title2 {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle2 {
    color: #dd8732 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-caption {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 16px !important;
    line-height: 25px;
    font-weight: 500;
}

.heading-caption2 {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 16px !important;
    line-height: 25px;
    font-weight: 500;
}

.item-subtitle {
    color: #E18122 !important;
    font-family: 'Urbanist', sans-serif !important;

}

.bg-feature {
    background-image: linear-gradient(45deg, #232526, #414345);
}

.center-items-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-tab {
    color: black;
}
</style>
  