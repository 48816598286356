<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="6"
      md="12"
      sm="12"
      cols="12"
      v-for="(service, i) in airtrafficItem"
      :key="i"
    >
      <div class="single-service service__style--siber d-flex align-center" style="height: 180px !important;">
        <!-- <router-link to="/service-details"> -->
          <div class="service d-flex flex-row">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content ml-5">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        <!-- </router-link> -->
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
import feather from "feather-icons";

  export default {
    components: {
    },
      data() {
        return {
          airtrafficItem: [
            {
              icon: "send",
              title: "ADS-B Receiver",
              desc:"Placing independent ADS-B receiver on certain location to detect the movement of aircraft with ADS-B transmitter enabled."
            },
            {
              icon: "cast",
              title: "Real-time Data Storing, Integration, Analytics & Visualization",
              desc:"Real time update of aircraft telemetry and metadata along with supporting data integration."
            },
            {
              icon: "rss",
              title: "Satellite Based Data",
              desc:"Complementing data on areas outside the coverage of the ADS-B Receiver with satellite detection data."
            },
            {
              icon: "monitor",
              title: "Potensi Use-Case (Manifest & FLIFO)",
              desc:"Complementing human movement data by combining manifest data and FIFO (Flight Information) with real-time data on aircraft movement and detection."

            },
          ],
        };
      },
      methods: {
        iconSvg(icon) {
          return feather.icons[icon].toSvg();
        },
      },
    };
</script>
<style scoped>
 .centang {
  vertical-align: top;
  min-width: 17px;
  padding-top: 5px;
  margin-bottom: 10px !important;
 }
 .feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
</style>
