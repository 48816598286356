<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="6"
      md="6"
      sm="6"
      cols="12"
      v-for="(service, i) in marineItem"
      :key="i"
    >
      <div class="single-service service__style--siber d-flex align-center" style="height: 230px !important;">
        <!-- <router-link to="/service-details"> -->
          <div class="service d-flex flex-row">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content ml-5">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        <!-- </router-link> -->
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
import feather from "feather-icons";

  export default {
    components: {
    },
      data() {
        return {
          marineItem: [
            {
              icon: "database",
              title: "Sibernetik In-platform Data Store and Processing",
              desc:"An all-in-one data storing platform that utilizes No-SQL data format, that saves files such as JSON format in a sequential manner by requesting a directory-like url for the file to be saved and be pulled"
            },
            {
              icon: "log-out",
              title: "In-platform Post",
              desc:"This feature is used as a protocol to save a data as files such as JSON, Blob, etc, and attaches the file to a Form-Data format and determine its directory for the file to be saved in In-platform using Directory-like url"
            },
            {
              icon: "log-in",
              title: "In-platform Get",
              desc:"This feature allows the user to access a file in In-platform by sending a Directory-like URL used to save a file previously by using In-platform Post"
            },
            {
              icon: "send",
              title: "In-platform SSE",
              desc:"This feature is used to track file changes inside In-platform. These files that were saved sequentially were tracked by the events of their updates that uses In-platform Post Protocol"
            },
          ],
        };
      },
      methods: {
        iconSvg(icon) {
          return feather.icons[icon].toSvg();
        },
      },
    };
</script>
<style scoped>
 .centang {
  vertical-align: top;
  min-width: 17px;
  padding-top: 5px;
  margin-bottom: 10px !important;
 }
 .feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
</style>
