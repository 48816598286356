<template>
  <div>
    <!-- Start Header Area -->
    <!-- <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile"/> -->
    <!-- <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none"/> -->
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <!-- <div
      class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
      data-black-overlay="1"
    >
      <v-container>
        <v-row>
            <v-col cols="12">
              <h2 class="heading-title1 text-h2 text-left font-weight-bold" style="font-size: 80px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                Air Traffic Surveillance
              </h2>
            </v-col>
            <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light" style="line-height: 40px !important;">Monitor and detect any anomalies in air traffic using our ready to use data, predifined<br>business logic and analytics.</h5>
            </v-col>
        </v-row>
      </v-container>

      <div class="video-background">
        <span>
          <video muted="" autoplay="" playsinline="" loop="" poster="">
            <source src="../assets/images/bg/air-traffic-login-screen.mp4" />
          </video>
        </span>
      </div>
    </div> -->
    <!-- End Slider Area -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--1" style="padding-top:0px; padding-bottom:0px">
      <div class="contact-form--1">
        <v-container>
          <div class="section rn-blog-area rn-section-gap" id="blog">
            <div class="section-title section-title--2 text-center mb--20">
              <h4 class="heading-title">Air Traffic Surveillance</h4>
              <h3 class="heading-title mb-5">
                Monitor air traffic in near real-time,<br>detect anomalies and get a custom alert
              </h3>
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              <p style="text-align: center;" class="my-8">Dark aircraft, Military aircraft, watchlist countries &
                airlines, hijacking, emergency alert, collision alert, incompliant flight path-speed-altitude, custom
                Area-of-Interest (AoI), etc.</p>
            </div>
            <ServiceAirTraffic />
          </div>
        </v-container>
      </div>
    </div>
    <!-- End Content -->

    <!-- Start feature Area  -->
    <div class="rn-team-area rn-section-gapsiber bg-feature">
      <v-container style="margin-top: 20px; margin-bottom: 20px">
        <FeatureArea />
      </v-container>
    </div>
    <!-- End feature Area  -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px" id="blog">
      <div class="contact-form--1">
        <v-container class="pa-0 d-flex justify-center" style="margin: auto;">
          <div class="section rn-blog-area rn-section-gap">
            <div class="section-title section-title--2 mb--20">
              <v-row>
                <v-col cols="6">
                  <v-img contain width="1700" height="auto" src="../assets/images/featured/airtraffic-monitor.png" />
                </v-col>
                <v-col cols="6" class="d-flex align-start pt-16">
                  <div class="d-flex flex-column justify-start" style="margin-bottom: 80px;">
                    <img src="../assets/images/icons/people says.svg"
                      style="position: absolute; z-index:1; width: auto; height: 150px; left:-3px; top:8px">
                    <div class="section-title section-title--2 text-justify mb--20"
                      style="position: absolute; z-index:2;">
                      <h4 class="heading-title" style="line-height: 44.8px !important;">

                        "Air Traffic Surveillance System utilizes multiple data sources, including ADS-B and satellite
                        data to capture aircraft's essential data
                        to later be visualized on a GIS Map Dashboard. These data, which are captured in real-time, can
                        later be stored and be used as data models for various
                        interest such as alerts, analytics, and much more."
                        
                      </h4>
                      <v-avatar class="mr-3">
                        <img src="../assets/images/blog/radit.png" alt="Developer">
                      </v-avatar>
                      <span class="grey--text" style="font-size: 18px">
                        Raditya Eko Prabowo, Developer.
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-container>
      </div>
    </div>
    <!-- End Content -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
import Contact from "../components/contact/Contact";
import FeatureArea from "../components/brand/BrandFeatureAirTraffic.vue";
import ServiceAirTraffic from "../components/service/ServiceAirTraffic.vue";
import Footer from "../components/footer/Footer";
export default {
  components: {
    HeaderOnePageTwo,
    HeaderOnePageTwoMobile,
    Contact,
    FeatureArea,
    ServiceAirTraffic,
    Footer,
  },
  data() {
    return {
      features: [
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/monitor.svg"),
          titleFeatures: "Map Based Flight Monitoring",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/info.svg"),
          titleFeatures: "Flight and Airport Information Panel",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/filter.svg"),
          titleFeatures: "Flight Search & Filtering",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/database.svg"),
          titleFeatures: "Real-time Data Source Processing",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/play.svg"),
          titleFeatures: "Playback Flights",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/bell.svg"),
          titleFeatures: "Custom Alert & Notification",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/pie-chart.svg"),
          titleFeatures: "Analytics & Reporting",
        },
      ]
    };
  },
};
</script>
<style scoped>
.heading-title1 {
  color: #dd8732 !important;
  line-height: 60px;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-title2 {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle2 {
  color: #dd8732 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-caption {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
  font-size: 16px !important;
  line-height: 25px;
  font-weight: 500;
}

.heading-caption2 {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
  font-size: 16px !important;
  line-height: 25px;
  font-weight: 500;
}

.item-subtitle {
  color: #E18122 !important;
  font-family: 'Urbanist', sans-serif !important;

}

.bg-feature {
  background-image: linear-gradient(45deg, #232526, #414345);
}</style>